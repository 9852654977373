import React from "react";
import { CorporateContactJsonLd, LocalBusinessJsonLd, NextSeo, SocialProfileJsonLd ,LogoJsonLd,WebPageJsonLd} from "next-seo";
 
export default class NextSeoModule extends React.Component {
    constructor(props){
        super(props);
    }
    render(){
      const structuredData = {
        "@context": "http://schema.org",
        "@type": "Review",
        "name": `${this.props.title}`,
        "url": `https://www.learnsoft.org/${this.props.url}`,
        "image": `https://www.learnsoft.org/${this.props.img}`,
        "author": {
          "@type": "Person",
          "name": "David"
        },
        "reviewBody": "The Azure DevOps training at learnsoft.org Institute was excellent. The course covered all the important concepts in a clear and concise manner. I appreciated the practical examples and real-world scenarios that were used to reinforce the lessons. I would definitely recommend this training to others.",
        "reviewRating": {
          "@type": "Rating",
          "ratingValue": "5"
        },
        "itemReviewed": {
          "@type": "Course",
          "name": `${this.props.title}`,
          "priceRange": "₹",
          "aggregateRating": {
            "@type": "AggregateRating",
            "ratingValue": "5",
            "reviewCount": "2289"
          },
          "provider": {
            "@type": "Organization",
            "name": "learnsoft.org",
            "sameAs": "https://www.learnsoft.org",
            "address": {
              "@type": "PostalAddress",
              "addressLocality": "Chennai",
              "addressRegion": "Tamil Nadu",
              "postalCode": "600097",
              "addressCountry": "India"
            },
            "telephone": "+91-7825888899"
          }
        }
      };
        return(
            <>
            <NextSeo
                title={this.props.title}
                description={this.props.description}
                canonical={`https://www.learnsoft.org/${this.props.url}`}
                additionalMetaTags={[
                    {
                      name: "keywords",
                      content: `${this.props.keyword}`,
                    },
                    {
                        name: "revisit-after",
                        content: "3 days",}
                  ]}
                openGraph={{
                    type: 'website',
                    locale:'en_US',
                    url: `https://www.learnsoft.org/${this.props.url}`,
                    title: `${this.props.title}`,
                    description: `${this.props.description}`,
                    images: [
                        {
                            url: `https://www.learnsoft.org/${this.props.img}`,
                            secureUrl:`https://www.learnsoft.org/${this.props.img}`,
                            width: 400,
                            height: 600,
                            type:'image/webp',
                            alt: 'learnsoft.org',
                        },
                    ],
                    site_name: "learnsoft.org",
                }}
                twitter={{
                  handle: "@learnsoft.org",
                  site: "@Learnsoftorg",
                  cardType: "summary_large_image",
                }}
                additionalScriptTags={[
                  {
                    type: 'application/ld+json',
                    innerHTML: JSON.stringify(structuredData),
                  },
                ]}
            />
            <LogoJsonLd
            id="https://www.learnsoft.org/logob.webp"
            name = "Logo - learnsoft.org"
            logo="https://www.learnsoft.org/logob.webp"
            url="https://www.learnsoft.org"
           />
            <CorporateContactJsonLd
                url="https://www.learnsoft.org"
                logo="https://www.learnsoft.org/logob.webp"
                name = "Contact - learnsoft.org"
                contactPoint={[
                    {
                        telephone: '+917825888899',
                        contactType: 'Enquiry',
                        areaServed: ["IN", "US", "CA"],
                        availableLanguage: ["English", "Tamil"],
                    }
                ]}
            />
            <SocialProfileJsonLd
                type="Organization"
                id="https://www.learnsoft.org"
                name="learnsoft.org"
                url="https://www.learnsoft.org"
                sameAs={[
                    'https://www.facebook.com/Learnsoft.org/',
                    'https://twitter.com/Learnsoftorg',
                    'https://www.linkedin.com',
                    'https://www.instagram.com/learnsoft_org/'
                   
                ]}
            />
            <LocalBusinessJsonLd
                type="LocalBusiness"
                priceRange = "₹"
                id="https://www.learnsoft.org/contact"
                name="learnsoft.org"
                description="learnsoft Provide Training, Internship and Placement"
                url="https://www.learnsoft.org/contact"
                telephone="+917825888899"
                address={{
                    "@type": "PostalAddress",
                    streetAddress: 'No.4/643,ADM Tower Second Floor,VOC street, OMR,Thoraippakkam',
                    addressLocality: 'Chennai',
                    addressRegion: 'Tamil Nadu',
                    postalCode: '600097',
                    addressCountry: 'INDIA',
                }}
                geo={{
                    "@type": "GeoCoordinates",
                    latitude: '12.9322375',
                    longitude: '80.2302378',
                }}
                images={[
                    `https://www.learnsoft.org/${this.props.img}`
                ]}
                openingHoursSpecification ={[
                    {
                      "@type": "OpeningHoursSpecification",
                      "opens": "08:30",
                      "closes": "21:00",
                      "dayOfWeek": [
                        "Monday",
                        "Tuesday",
                        "Wednesday",
                        "Thursday",
                        "Friday",
                        "Saturday"
                      ]
                    }
                  ]}
            />
            </>
        )
    }
}